import React, { type PropsWithChildren } from 'react';
import { ThemeProvider, type DefaultTheme } from 'styled-components';
import { GlobalStyles } from './GlobalStyles';

export const GlobalWrapper: React.FC<PropsWithChildren<{ theme: DefaultTheme }>> = ({ children, theme }) => (
  <>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  </>
);
