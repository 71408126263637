import type { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Spread } from '../utility/Spread';

export const Card: React.FC<PropsWithChildren<{ title?: ReactNode; rightElem?: ReactNode; className?: string }>> = ({
  title,
  rightElem,
  children,
  className,
}) => (
  <CardContainer className={className}>
    {(title || rightElem) && (
      <Spread>
        {typeof title === 'string' ? <h2>{title}</h2> : (title ?? <span>&nbsp;</span>)}
        {typeof rightElem === 'string' ? <span>{rightElem}</span> : (rightElem ?? <span>&nbsp;</span>)}
      </Spread>
    )}
    {typeof children === 'string' ? <span>{children}</span> : children}
  </CardContainer>
);

const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.panelBackground};
  box-shadow: 1px 2px 3px 0px #0c17360f;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
