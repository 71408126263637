import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from '../components/Card';

export const Overview: React.FC = () => {
  const params = useParams();
  return (
    <Layout>
      <Card title={<h2>Column One</h2>} rightElem={<a href="#">a link</a>}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus est nec tristique egestas. Aliquam
        euismod ullamcorper nulla vel ultricies. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet
        tincidunt arcu. Maecenas commodo posuere diam id consequat. Vivamus dignissim dapibus commodo. Nulla sed
        venenatis metus. Nunc urna ipsum, sagittis ac felis eu, sagittis bibendum dui. Donec enim est, porta eu nunc ut,
        consectetur consectetur erat. Etiam orci lorem, condimentum eget leo nec, pulvinar condimentum est. Aliquam
        finibus a velit quis placerat. Vestibulum dignissim tellus et iaculis bibendum. Vestibulum ante ipsum primis in
        faucibus orci luctus et ultrices posuere cubilia curae; Integer tristique lorem in nisi tristique, id viverra
        orci vestibulum. Ut varius auctor enim, ut tempor erat pharetra a. Pellentesque maximus efficitur tempor. Sed
        eros urna, aliquam mattis egestas eget, volutpat ac orci. Duis bibendum ante elementum, hendrerit leo ut,
        faucibus eros. Sed blandit diam non diam faucibus venenatis. Fusce tellus turpis, lacinia in euismod quis,
        egestas vel risus. Ut non ultricies nibh, sed pulvinar eros.
      </Card>
      <Card title={<h2>Column Two</h2>}>
        <p>Overview page. Tenant : {params.tenantId}</p>
        <p>
          Quisque bibendum viverra ligula in faucibus. Duis id mattis metus. Donec quis tortor turpis. Donec tincidunt
          est eu ullamcorper imperdiet. Integer varius mauris sed tristique ullamcorper. Mauris a lacus id odio varius
          dignissim ac et quam. Nullam at nulla rhoncus, dictum felis tincidunt, laoreet orci. Ut sollicitudin lacus
          fringilla, consequat orci quis, elementum libero. Maecenas bibendum metus et neque tincidunt, lobortis aliquam
          purus volutpat. Sed augue nulla, tristique quis risus et, auctor hendrerit nisl. Pellentesque elementum
          accumsan ullamcorper. Suspendisse eleifend sem vitae rhoncus fringilla. Morbi sed euismod diam. Ut ac erat
          ornare, convallis dolor et, imperdiet augue. Sed iaculis commodo ex, vel accumsan magna pellentesque
          tincidunt. Mauris eleifend gravida rutrum. Quisque at dictum leo. Nullam quis elit ultrices, laoreet mi ac,
          hendrerit tellus. Aenean vitae turpis ipsum. Aenean semper pulvinar justo, eu maximus lorem viverra nec.{' '}
        </p>
      </Card>
      <Card title={<h2>Column Three</h2>}>
        Praesent scelerisque urna et nulla sollicitudin congue. Nunc in feugiat nibh, in pharetra nisi. Suspendisse a
        tellus eu nibh interdum pretium non sed quam. Quisque feugiat placerat mattis. Suspendisse vel laoreet tellus,
        id eleifend risus. Sed diam nisl, porttitor ac interdum eget, pharetra eget quam. Sed nisi libero, cursus a
        turpis vitae, scelerisque consequat sem. Aliquam vitae porta ante. Nullam sit amet neque vitae tellus sodales
        iaculis. Donec eros tellus, malesuada ut interdum ac, facilisis id sapien. Sed eu diam accumsan, finibus orci
        sed, tempus nulla. Fusce feugiat tellus at lorem mattis volutpat. Suspendisse laoreet id risus vel lacinia.
        Vestibulum eget efficitur erat. Hello Sir Chris, all good here!!
      </Card>
    </Layout>
  );
};

const Layout = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 2fr 1fr;
  min-height: 100%;
`;
