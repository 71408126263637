import { type TopBarUIProps } from './TopBarUI';
import { topbarMenuPages } from '../../menuPages';
import { useMemo } from 'react';
import avatarUrl from './assets/user.png';
import logo from './assets/OTLogo.svg?url';
import { useZustandStore } from '../../appState/StoresProvider';

export const useTopBarUIProps = (): TopBarUIProps => {
  const { logOut } = useZustandStore('auth');
  return useMemo(
    () => ({
      pages: topbarMenuPages,
      user: {
        name: 'John Doe',
        role: 'Admin',
        avatarUrl,
      },
      logoUrl: logo,
      onLogoff: () => {
        logOut();
      },
    }),
    [logOut],
  );
};
