import { createBrowserRouter, json, redirect, type RouteObject } from 'react-router-dom';
import { PreserveSearchParams } from './routes/PreserveSearchParams';
import { ErrorRoute } from './routes/Error';
import { MainLayout } from './routes/MainLayout';
import { ProtectedRoute } from './routes/ProtectedRoute';
import { Login } from './routes/Login';
import { topbarMenuPages } from './menuPages';

const searchParamsToPreserve: string[] = [];

function getAppRoutes(isOutthinkOrigin: boolean) {
  const basicAppRoutes: RouteObject[] = [
    {
      index: true,
      loader({ params: { tenantId } }) {
        const loaderResponse = isOutthinkOrigin
          ? (testTenantId(tenantId) ?? redirect('overview'))
          : redirect('overview');
        return loaderResponse;
      },
    },
    {
      path: '',
      element: (
        <ProtectedRoute
          storePreviousLocationOnRedirect
          allowWhen={(loginStatus) => loginStatus === 'loggedIn'}
          redirectTo="login"
        />
      ),
      children: [
        {
          path: '',
          element: <MainLayout />,
          children: topbarMenuPages,
        },
      ],
    },
    {
      path: 'login',
      element: (
        <ProtectedRoute
          restoreStoredLocationOnRedirect
          allowWhen={(loginStatus) => loginStatus !== 'loggedIn'}
          fallbackRedirectTo="../overview"
        />
      ),
      children: [{ path: '', element: <Login /> }],
    },
  ];
  return isOutthinkOrigin ? withTenantId(basicAppRoutes) : basicAppRoutes;
}

export function getRouter() {
  const isOutthinkOrigin = window.location.origin === import.meta.env.REACT_APP_OUTTHINK_ORIGIN;
  const appRoutes = getAppRoutes(isOutthinkOrigin);
  return createBrowserRouter([
    {
      path: '',
      element: <PreserveSearchParams paramKeys={searchParamsToPreserve} />,
      errorElement: <ErrorRoute />,

      children: [
        ...appRoutes,
        {
          path: '',
          loader({ params: { tenantId } }) {
            if (isOutthinkOrigin) {
              try {
                testTenantId(tenantId);
              } catch {
                return redirect('/DEMO');
              }
            }
            throw json(null, { status: 404, statusText: 'Not found' });
          },
        },
      ],
    },
  ]);
}
function withTenantId(appRoutes: RouteObject[]): RouteObject[] {
  return [
    {
      path: ':tenantId',
      loader({ params }) {
        return testTenantId(params.tenantId);
      },
      children: appRoutes,
    },
  ];
}
function testTenantId(tenantId: string | undefined) {
  if (tenantId == null || !/^[A-Z]{4}$/.exec(tenantId)) {
    throw json(null, { status: 404, statusText: 'Not found. Invalid tenant code.' });
  }
  return null;
}
