import { createZustandStoresContext } from '../lib/storeContext/createZustandStoresContext';
import * as stateCreators from './stateCreators';

export const { ZustandStoresProvider, useZustandStore } = createZustandStoresContext({
  stateCreators,
  persistKeys: ['auth'],
  init: async ({ auth }) => {
    if (auth.getState().loginStatus === 'unknown') {
      await delay(500);
      auth.setState({ loginStatus: 'loggedOut' });
    }
  },
});

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
