import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Help from './assets/help.svg';
import Bell from './assets/bell.svg';
import { Spread } from '../utility/Spread';
import { Card } from '../Card';

export type TopBarUIProps = {
  pages: {
    name: string;
    path: string;
  }[];
  user: {
    name: string;
    role: string;
    avatarUrl: string;
  };
  logoUrl: string;
  onLogoff: () => void;
};
export const TopBarUI: React.FC<TopBarUIProps> = ({ pages, user, logoUrl, onLogoff }) => {
  // const { logOut } = useStores('auth');
  return (
    <Menu>
      <Spread>
        <ItemGroup>
          <img src={logoUrl} alt="Logo" />
          <Links>
            {pages.map(({ name, path }) => (
              <NLink key={path} to={path}>
                {name}
              </NLink>
            ))}
          </Links>
        </ItemGroup>
        <ItemGroup>
          <Help />
          <Bell />
          <User name={user.name} role={user.role} />
          <UserImg src={user.avatarUrl} alt="User Avatar" onClick={onLogoff} title="Log off" />
        </ItemGroup>
      </Spread>
    </Menu>
  );
};

const UserImg = styled.img`
  border-radius: 50%;
  width: 40px;
  cursor: pointer;
`;
const UserName = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.heading};
`;
const User: React.FC<{ name: string; role: string }> = ({ name, role }) => {
  return (
    <UserContainer>
      <UserName>{name}</UserName>

      <p>{role}</p>
    </UserContainer>
  );
};
const UserContainer = styled.div``;

const ItemGroup = styled(Spread)`
  gap: 32px;
`;

const NLink = styled(NavLink)`
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: inherit;
  height: 100%;
  margin: 0;
  padding: 0;

  &.active {
    color: ${({ theme }) => theme.colors.anchor};
    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background-color: ${({ theme }) => theme.colors.anchor};
      position: absolute;
      bottom: 0;
    }
  }
`;

const Menu = styled(Card)`
  padding: 0 16px;
  position: relative;
`;

const Links = styled.div`
  display: flex;
  gap: 2em;
  position: relative;
  height: 56px;
`;
