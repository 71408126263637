export function getTheme() {
  return {
    colors: {
      pageBackground: '#EEF1F9',
      panelBackground: '#FFFFFF',
      normal: '#596180',
      heading: '#292B33',
      anchor: '#5170ef',
    },
    fontSizes: {
      normal: '12px',
      heading: '16px',
    },
    borderRadius: {
      normal: '8px',
    },
  };
}
