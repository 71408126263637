import type React from 'react';
import { useParams } from 'react-router-dom';
import { useZustandStore } from '../appState/StoresProvider';

export const Login: React.FC = () => {
  const { tenantId } = useParams();

  return (
    <div>
      <h1>Login</h1>
      {tenantId && <p>tenantId: {tenantId}</p>}
      <div>
        <LoginButton />
      </div>
    </div>
  );
};

const LoginButton = function () {
  const { loginStatus, logIn, logOut } = useZustandStore('auth');

  switch (loginStatus) {
    case 'unknown':
      return <p>Waiting for login status...</p>;
    case 'loggedIn':
      return <button onClick={logOut}>Log out</button>;
    case 'loggingIn':
      return <p>Logging in...</p>;
    case 'loggingOut':
      return <p>Logging out...</p>;
    case 'loggedOut':
      return <button onClick={() => logIn()}>Log in</button>;
  }
};
